.animated-component,
.animated-component-alternative,
.animated-component-top-down {
    opacity: 0;
}

.animated-component-top-down.in-view {
    animation: myAnimationTopDown 800ms ease-in-out;
    transition-delay: 300ms;
    animation-delay: 300ms;
    opacity: 1;
}

.animated-component.in-view {
    animation: myAnimation 800ms ease-in-out;
    transition-delay: 200ms;
    animation-delay: 200ms;
    opacity: 1;
}

.animated-component-alternative.in-view {
    animation: myAnimationReverse 800ms ease-in-out;
    transition-delay: 200ms;
    animation-delay: 200ms;
    overflow: hidden;
    opacity: 1;
}

@keyframes myAnimation {
    from {
        opacity: 0;
        transform: translateX(-100vw);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes myAnimationReverse {
    from {
        opacity: 0;
        transform: translateX(100vw);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes myAnimationTopDown {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
