.h1 {
    @apply text-[40px] font-semibold leading-[60px] tracking-[-0.4px];
}

.h2 {
    @apply text-[24px] font-semibold leading-[36px];
}

.h3 {
    @apply text-[16px] font-semibold leading-[21px];
}

.p {
    @apply text-[24px] leading-[36px] tracking-[0.72px];
}

.p1 {
    @apply text-[20px] font-medium leading-[34px];
}

.p2 {
    @apply text-[16px] leading-[24px];
}

.label {
    @apply text-[16px] font-semibold leading-[24px];
}

.label2 {
    @apply text-[14px] font-medium;
}

.buttonText {
    @apply text-[16px] font-medium leading-normal tracking-[0.48px];
}

.h1Mobile {
    @apply text-[32px] font-semibold leading-[48px] tracking-[-0.32px];
}

.h2Mobile {
    @apply text-[16px] font-semibold leading-[24px];
}

.p1Mobile {
    @apply text-[16px] font-medium leading-[24px];
}

.pMobile {
    @apply text-[20px] leading-[30px] tracking-[0.6px];
}

@media (max-width: 768px) {
    .h2Responsive {
        @apply h2Mobile;
    }

    .h1Responsive {
        @apply h1Mobile;
    }

    .p1Responsive {
        @apply p1Mobile;
    }

    .pResponsive {
        @apply pMobile;
    }

    .h1eResponsive {
        @apply h2;
    }
}

@media (min-width: 768px) {
    .h2Responsive {
        @apply h2;
    }

    .h1Responsive {
        @apply h1;
    }
    .h1eResponsive {
        @apply h1;
    }

    .p1Responsive {
        @apply p1;
    }

    .pResponsive {
        @apply p;
    }
}
